import * as React from 'react';
import { useState, useEffect, Fragment, useContext } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import moment from 'moment';
// Requests
import { detailClientReq } from '../../../../../api/client/detailClient.jsx';
import { columnsHistoryHttpTable, renderHistoryHttpTable } from '../../client.utils.jsx';
import { columnsSummaryHttpTable, renderSummaryHttpTable } from '../../client.utils.jsx';
import { historyClientHttpReq,
  summaryClientHttpReq,
  downloadClientHttpHistoryReq,
  summaryByCountryClientHttpReq,
  summaryByPlateformClientHttpReq,
} from '../../../../../api/client/historyClient.jsx';

// UI
import "../detailclient.scss";
import ClientDetailFilterTabs from '../client_filter_tabs.jsx';
import ClientDetailV2Header from '../client_header.jsx';
import Head from '../../../../../components/head/index.jsx';
import Table from '../../../../../components/table';
import Button from '../../../../../components/button/index.jsx';
import BlocWithTitle from '../../../../../components/blocWithTitle/index.jsx';

import IconLoading from '../../../../../components/loading/loading.jsx';
import { IconDownload } from '../../../../../assets/icons/icons-search-download.jsx';

// Context
import PermissionsContext from '../../../../../contexts/permissionContext.js';

// Graphiques
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from 'chart.js';
import { Pie } from 'react-chartjs-2';
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
);

const pieData = (res) => {
  let dataLabels = [];
  res.map((item) => dataLabels.push(item.country));
  let datas = [];
  res.map((item) => datas.push(item.count));
  let result = {
    labels: dataLabels,
    datasets: [
      {
        label: '# / Pays',
        data: datas,
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(255, 159, 64, 0.2)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };
  return result;
};

const piePlateformData = (res) => {
  let dataLabels = [];
  res.map((item) => dataLabels.push(item.plateform));
  let datas = [];
  res.map((item) => datas.push(item.count));
  let result = {
    labels: dataLabels,
    datasets: [
      {
        label: '# / Plateform',
        data: datas,
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(255, 159, 64, 0.2)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };
  return result;
};

const emptyPieData = {
  labels: [],
  datasets: [
    {
      label: '# / Pays',
      data: [],
      borderWidth: 1,
    },
  ],
};

const emptyPiePlateformData = {
  labels: [],
  datasets: [
    {
      label: '# / Pays',
      data: [],
      borderWidth: 1,
    },
  ],
};

function ClientDetailV2History() {
  const { id } = useParams();
  //const { useParams, setUsedPatams } = useParams();
  const [detailData, setDetailData] = useState();
  const [loading, setLoading] = useState(false);
  const [tableHistoryData, setTableHistoryData] = useState([]);
  const [tableSummaryData, setTableSummaryData] = useState([]);
  const [pieChartData, setPieChartData] = useState(emptyPieData);
  const [pieChartPlateformData, setPieChartPlateformData] = useState(emptyPiePlateformData);
  const [pieChartLoading, setPieChartLoading] = useState(false);
  const [pieChartPlateformLoading, setPieChartPlateformLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [status, setStatus] = useState('loading');
  const [csvLoading, setCsvLoading] = useState(false);

  const { permContext } = useContext(PermissionsContext);

  useEffect(() => {
    refreshData();

    historyClientHttpReq(id)
      .then((res) => {
        //console.log(res);
        setTableHistoryData(renderHistoryHttpTable(res));
      })
      .finally(() => setStatus('done'));

    summaryClientHttpReq(id)
      .then((res) => {
        //console.log(res);
        setTableSummaryData(renderSummaryHttpTable(res));
      })
      .finally(() => setStatus('done'));

    setPieChartLoading(true);
    summaryByCountryClientHttpReq(id)
      .then((res) => {
        //console.log(res);
        setPieChartData(pieData(res));
      })
      .finally(() => setPieChartLoading(false));

    setPieChartPlateformLoading(true);
    summaryByPlateformClientHttpReq(id)
      .then((res) => {
        //console.log(res);
        setPieChartPlateformData(piePlateformData(res));
      })
      .finally(() => setPieChartPlateformLoading(false));
  }, []);

  const refreshData = (force) => {
    setLoading(true);
    detailClientReq(id, force).then((res) => {
      setDetailData(res);
      setLoading(false);
    });
  };

  const downloadCsv = async (userId) => {
    if (userId) {
      setCsvLoading(true);
      const res = await downloadClientHttpHistoryReq(userId);
      setCsvLoading(false);
    }
  };

  function headTitle() {
    return (
      <Fragment>
        <div className="o-breadcrumb">
          <a href={'/dashboard/client'}>
            Liste clients&nbsp;&nbsp;/&nbsp;&nbsp;
          </a>
          Détails client
        </div>
      </Fragment>
    );
  }

  function rightHeaderActions() {
    return (
      <Button
        btnType="outline"
        className="ml-4 mb-4"
        onClick={() => {
          downloadCsv(id);
        }}
        icon={csvLoading ? <IconLoading /> : <IconDownload />}
      >
        Télécharger CSV
      </Button>
    );
  }

  return (
    <div>
      <div className="head-content">
        <div className="o-head">
          <Head headTitle={headTitle()} disabledSearch rightFragment={rightHeaderActions()} />
        </div>
      </div>
      <div>
        <ClientDetailV2Header detailData={detailData}/>
      </div>
      <div className="o-content">
        <div className="o-content__filters">
          <ClientDetailFilterTabs clientId={id} />
        </div>
        <div className="o-content__detailclient">
          <div className="o-content__detailclient__title">
            <b>Historique http client</b>
          </div>
          <div className="o-content__detailclient__row">
            <div className="o-content__detailclient__row__column col-2">&nbsp;</div>
            <div className="o-content__detailclient__row__column col-3">
              {pieChartLoading ? <IconLoading /> : <Pie data={pieChartData} />}
            </div>
            <div className="o-content__detailclient__row__column col-2">&nbsp;</div>
            <div className="o-content__detailclient__row__column col-3">
              {pieChartPlateformLoading ? <IconLoading /> : <Pie data={pieChartPlateformData} />}
            </div>
            <div className="o-content__detailclient__row__column col-2">&nbsp;</div>
          </div>
          <div className="o-content__detailclient__row">
            <div className="o-content__detailclient__row__column col-6">
            <Table
              showModal={showModal}
              setShowModal={setShowModal}
              columns={columnsSummaryHttpTable}
              tableData={tableSummaryData}
              status={status}
              textLeft
              ContainHeader={() => ''}
              contain
              itemPerPage={10}
            />
            </div>
            <div className="o-content__detailclient__row__column col-6">
            <Table
              showModal={showModal}
              setShowModal={setShowModal}
              columns={columnsHistoryHttpTable}
              tableData={tableHistoryData}
              status={status}
              textLeft
              ContainHeader={() => ''}
              contain
              itemPerPage={10}
            />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ClientDetailV2History;

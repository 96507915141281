import React, { useEffect, useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PermissionsContext from '../../../contexts/permissionContext';
import Head from '../../../components/head';
import './supervision.scss';

import { IconPermission, IconMeter } from '../../../assets/icons/icons-sidebar.jsx';
import { ReactComponent as IconProfile } from '../../../assets/icons/component/ic_profile.svg';

const SupervisionCenter = () => {
  const { t } = useTranslation();
  const { permContext } = useContext(PermissionsContext);
  const activeStyle = { backgroundColor: 'var(--primary-dark)', color: 'var(--black)' };

  useEffect(() => {}, []);

  return (
    <div>
      <Head headTitle="Centre de Surveillance" HeadIcon={IconPermission} disabledSearch={true} />
      <div className="row mt-5 ml-5">
        {permContext?.clientRead && (
          <NavLink
            className="o-supervision-item"
            to="/dashboard/supervision/client_fraud_suspected"
            activeStyle={activeStyle}
          >
            <IconProfile />
            <div> {t('sidebar:client_fraud_suspected')}</div>
          </NavLink>
        )}
        {permContext?.clientRead && (
          <NavLink
            className="o-supervision-item"
            to="/dashboard/supervision/payin_alerts"
            activeStyle={activeStyle}
          >
            <IconMeter />
            <div>Payin Alertes</div>
          </NavLink>
        )}
        {permContext?.clientRead && (
          <NavLink
            className="o-supervision-item"
            to="/dashboard/supervision/sddr_rejected"
            activeStyle={activeStyle}
          >
            <IconMeter />
            <div>SDDR Rejets</div>
          </NavLink>
        )}
        {permContext?.clientRead && (
          <NavLink
            className="o-supervision-item"
            to="/dashboard/supervision/client_to_recover"
            activeStyle={activeStyle}
          >
            <IconProfile />
            <div> {t('sidebar:client_to_recover')}</div>
          </NavLink>
        )}
        {permContext?.clientRead && (
          <NavLink
            className="o-supervision-item"
            to="/dashboard/supervision/client_overdraft"
            activeStyle={activeStyle}
          >
            <IconProfile />
            <div> {t('sidebar:client_overdraft')}</div>
          </NavLink>
        )}
      </div>
    </div>
  );
};

export default SupervisionCenter;

import React, { useState, useEffect, useContext } from 'react';
import { NavLink, useParams } from 'react-router-dom'; // useParams non utilisé
import { useTranslation } from 'react-i18next';
import PermissionsContext from '../../../contexts/permissionContext';
import Head from '../../../components/head';
import './admin.scss';
import Button from '../../../components/button';
import { backendApiEndpoint } from '../../../api/constants.js';
import { CheckAuth, instanceBackendApi } from '../../../api/index.jsx';
import IconLoading from '../../../components/loading/loading.jsx';
import { ReactComponent as IconProfile } from '../../../assets/icons/component/ic_profile.svg';
import {
  IconPermission,
  IconGroup,
  IconUser,
  IconDocument,
  IconPackage,
  IconTypes,
} from '../../../assets/icons/icons-sidebar.jsx';

const AdministrationCenter = () => {
  const { t } = useTranslation();
  const { permContext } = useContext(PermissionsContext);
  const [Loading, setLoading] = useState(false);
  const activeStyle = { backgroundColor: 'var(--primary-dark)', color: 'var(--black)' };

  // const [showModal, setShowModal] = useState(false);
  const [status, setStatus] = useState('loading'); // 'status' et 'setStatus' jamais utilisés

  async function updateRemoteConfig() {
    try {
      setLoading(true);
      await instanceBackendApi.get(backendApiEndpoint.remoteconfig_update);
      console.log('Remoteconfig updated successfully for: jarvis');
      setLoading(false);
    } catch (error) {
      setLoading(true);
      CheckAuth(error);
      console.error('Failed to update remoteconfig for: jarvis');
      throw error;
    }
  }

  useEffect(() => {}, []);

  return (
    <div>
      <Head headTitle="Centre d'Administration" HeadIcon={IconPermission} disabledSearch={true} />
      <div className="row mt-5 ml-5">
        {permContext?.permissionRead && (
          <div>
            <NavLink
              exact
              className="o-admin-item"
              to="/dashboard/admin/permissions"
              activeStyle={activeStyle}
            >
              <IconPermission />
              <div>{t('sidebar:permission')}</div>
            </NavLink>
          </div>
        )}
        {permContext?.groupRead && (
          <div>
            <NavLink
              exact
              className="o-admin-item"
              to="/dashboard/admin/groups"
              activeStyle={activeStyle}
            >
              <IconGroup />
              <div> {t('sidebar:groups')}</div>
            </NavLink>
          </div>
        )}
        {permContext?.userRead && (
          <div>
            <NavLink
              exact
              className="o-admin-item"
              to="/dashboard/admin/users"
              activeStyle={activeStyle}
            >
              <IconUser />
              <div> {t('sidebar:users')}</div>
            </NavLink>
          </div>
        )}
        {permContext?.docRead && (
          <div>
            <NavLink
              exact
              className="o-admin-item"
              to="/dashboard/admin/documents"
              activeStyle={activeStyle}
            >
              <IconDocument />
              <div> {t('sidebar:documents')}</div>
            </NavLink>
          </div>
        )}
        {permContext?.codePromoRead && (
          <div>
            <NavLink
              exact
              className="o-admin-item"
              to="/dashboard/admin/codepromos"
              activeStyle={activeStyle}
            >
              <IconPermission />
              <div>Codes Promotionnels</div>
            </NavLink>
          </div>
        )}
        {permContext?.codePromoRead && (
          <div>
            <NavLink
              exact
              className="o-admin-item"
              to="/dashboard/admin/waitinglist"
              activeStyle={activeStyle}
            >
              <IconPermission />
              <div>Liste d'attente</div>
            </NavLink>
          </div>
        )}
        {permContext?.codePromoRead && (
          <div>
            <NavLink
              exact
              className="o-admin-item"
              to="/dashboard/admin/invitationcodes"
              activeStyle={activeStyle}
            >
              <IconPermission />
              <div>Codes d'invitation</div>
            </NavLink>
          </div>
        )}
        {permContext?.featureRead && (
          <div>
            <NavLink
              exact
              className="o-admin-item"
              to="/dashboard/admin/package-features"
              activeStyle={activeStyle}
            >
              <IconPackage />
              <div> {t('sidebar:features')}</div>
            </NavLink>
          </div>
        )}
        {permContext?.typeRead && (
          <div>
            <NavLink
              exact
              className="o-admin-item"
              to="/dashboard/admin/package-types"
              activeStyle={activeStyle}
            >
              <IconTypes />
              <div> {t('sidebar:types')}</div>
            </NavLink>
          </div>
        )}
        {permContext?.partnershipRead && (
          <div>
            <NavLink className="o-admin-item" to="/dashboard/partnership" activeStyle={activeStyle}>
              <IconProfile />
              <div>{t('sidebar:partnership')}</div>
            </NavLink>
          </div>
        )}
      </div>
      <div className="row mt-5 ml-5">
        <Button
          btnType="outline"
          onClick={updateRemoteConfig}
          icon={Loading ? <IconLoading /> : null}
        >
          Mettre à jour les Remoteconfig
        </Button>
      </div>
    </div>
  );
};

export default AdministrationCenter;

import * as React from 'react';
import { useState, useEffect, Fragment, useContext } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import moment from 'moment';

// Requests
import {
  detailClientReq,
} from '../../../../../api/client/detailClient.jsx';
import { getClientMembershipReq } from '../../../../../api/client/clientmembership.jsx';

// UI
import "../detailclient.scss";
import ClientDetailV2Header from '../client_header.jsx';
import Button from '../../../../../components/button/index.jsx';
import ClientDetailFilterTabs from '../client_filter_tabs.jsx';
import Head from '../../../../../components/head/index.jsx';
import BlocWithTitle from '../../../../../components/blocWithTitle/index.jsx'
import { ReactComponent as IconEdit } from '../../../../../assets/icons/component/ic_edit.svg';
import InfoItem from '../../../../../components/infoItem/index.jsx';

// Modals
import ConfirmationUpdatePayoutLimits from '../../modals/membership/confirmationUpdatePayoutLimits.jsx';
import ConfirmationUpdateClientMembership from '../../modals/membership/confirmationUpdateClientMembership.jsx';

import useFormattedAmount from "../../../../../hooks/useFormattedAmount";

import PermissionsContext from '../../../../../contexts/permissionContext.js';

function ClientDetailV2Membership() {
  const { id } = useParams();
  //const { useParams, setUsedPatams } = useParams();
  const [status, setStatus] = useState('loading');
  const [detailData, setDetailData] = useState();
  const [loading, setLoading] = useState(false);
  
  // Modals
  const [updatePayoutLimitsModal, setUpdatePayoutLimitsModal] = useState(false);
  const [updateClientMembershipModal, setUpdateClientMembershipModal] = useState(false);

  const { permContext } = useContext(PermissionsContext);

  useEffect(() => {
    refreshData();

  }, []);

  function headTitle() {
    return (
      <Fragment>
        <div className="o-breadcrumb">
          <a href={status ? '/dashboard/clientbystatus/' + status : '/dashboard/client'}>
            Liste clients&nbsp;&nbsp;/&nbsp;&nbsp;
          </a>
          Détails client
        </div>
      </Fragment>
    );
  }

  const refreshData = (force) => {
    setLoading(true);
    detailClientReq(id, force).then((res) => {
      setDetailData(res);
      setLoading(false);
    });

    getClientMembershipReq(id)
      .then((res) => {
        setDetailData(res);
        setLoading(false);
      })
      .finally(() => setStatus('done'));
  };

  return (
    <div>
      <div className="head-content">
        <div className="d-flex o-head">
          <Head headTitle={headTitle()} disabledSearch />
        </div>
      </div>
      <div>
        <ClientDetailV2Header detailData={detailData}/>
      </div>
      <div className="o-content">
        <div className="o-content__filters">
          <ClientDetailFilterTabs clientId={id} />
        </div>
        <div className="o-content__detailclient">
          <div className="o-content__detailclient__row">
            <div className="o-content__detailclient__row__column col-5">
              <BlocWithTitle title="Informations Forfait">
                <InfoItem title="Forfait" value={detailData?.type?.name}/>
                <InfoItem title="Prix" value={useFormattedAmount(detailData?.price) + '(' + detailData?.billing_type + ')'}/>
                <InfoItem title="Nb de wallets gratuits" value={detailData?.numberOfWalletAllowed}/>
                <InfoItem title="Nb de retraits ATM gratuits" value={detailData?.numberOfWithdrawalAllowed}/>
              </BlocWithTitle>
            </div>
            <div className="o-content__detailclient__row__column col-5">
              <BlocWithTitle title="Limites">
              <InfoItem title="Création Wallet">{detailData?.current_wallet_created}&nbsp;Wallets&nbsp;créés&nbsp;/&nbsp;
              {detailData?.limit_wallet_created}&nbsp;Wallets&nbsp;autorisés</InfoItem>
              <InfoItem title="Création Cartes">{detailData?.current_card_created}&nbsp;Cartes&nbsp;créées&nbsp;/&nbsp;
              {detailData?.limit_card_created}&nbsp;Cartes&nbsp;autorisées</InfoItem>
              <InfoItem title="Virement externe / jour">
                    {useFormattedAmount(detailData?.current_payout_amount_daily)}{' / '}
                    {useFormattedAmount(detailData?.limit_payout_amount_daily)}
              </InfoItem>
              <InfoItem title="Virement externe / mois">
                  {useFormattedAmount(detailData?.current_payout_amount_monthly)}{' / '}
                  {useFormattedAmount(detailData?.limit_payout_amount_monthly)}
              </InfoItem>
              </BlocWithTitle>
            </div>
            <div className="o-content__detailclient__row__column col-2">
              <div className="o-information-detail__actions">
                <Button btnType="outline" onClick={() => setUpdatePayoutLimitsModal(true)}>
                  <IconEdit/>
                  Modifier plafonds Virement externe
                </Button>
                <Button btnType="outline" onClick={() => setUpdateClientMembershipModal(true)}>
                  <IconEdit/>
                  Changer de Forfait
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ConfirmationUpdatePayoutLimits
        userID={id}
        membership={detailData}
        updatePayoutLimitsModal={updatePayoutLimitsModal}
        setUpdatePayoutLimitsModal={setUpdatePayoutLimitsModal}
        refreshData={refreshData}
      />
      <ConfirmationUpdateClientMembership
        userID={id}
        membership={detailData}
        updateClientMembershipModal={updateClientMembershipModal}
        setUpdateClientMembershipModal={setUpdateClientMembershipModal}
        refreshData={refreshData}
      />
    </div>
  );
}

export default ClientDetailV2Membership;
